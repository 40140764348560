var moment = require('moment-timezone')

angular.module('starter.services').factory('PayrollService', function (Service, $q, $rootScope, DateService, Http2) {
  function getNextPayrollDate(payRuns, company, payPeriod) {
    // START : get pay period start date $ end date
    var payStartDate

    // find payrun start date
    if (!payRuns || payRuns.length == 0) {
      // never created employee
      payStartDate = company.sd ? new Date(company.sd) : new Date()
    } else {
      // 이미 생성된게 있을경우 처음부터 마지막 employee date 에서부터 구한다.
      var lastPayRunEndDate, lastPayRunEndDateForThisPayPeriod
      for (let index = 0; index < payRuns.length; index++) {
        const payRun = payRuns[index]
        var payRunEndDate = new Date(payRun.ed)

        // 1. pay period 같은 payrun 이 있을 경우 그 이후 pay run 이 시작되는 것으로 한다.
        // 2. pay period 가 존재 하지 않으면 마지막 날을 기준으로 새로 만든다.

        if (!lastPayRunEndDate || lastPayRunEndDate < payRunEndDate) {
          lastPayRunEndDate = payRunEndDate
        }

        if (
          payRun.pp == payPeriod &&
          (!lastPayRunEndDateForThisPayPeriod || lastPayRunEndDateForThisPayPeriod < payRunEndDate)
        ) {
          lastPayRunEndDateForThisPayPeriod = payRunEndDate
        }
      }

      lastPayRunEndDate = lastPayRunEndDateForThisPayPeriod || lastPayRunEndDate

      // get next day
      payStartDate = angular.copy(lastPayRunEndDate)
      payStartDate.setDate(payStartDate.getDate() + 1)

      // payRunData_temp.sd = payStartDate;
    }

    var diff_paymentDate = company.pd_df != null ? company.pd_df : 3
    var payEndDate
    var numberOfDays

    switch (payPeriod || company.pp) {
      case 'w':
        numberOfDays = 6
        payEndDate = angular.copy(payStartDate)
        payEndDate.setDate(payEndDate.getDate() + numberOfDays)
        break
      case 'f':
        numberOfDays = 13
        payEndDate = angular.copy(payStartDate)
        payEndDate.setDate(payEndDate.getDate() + numberOfDays)
        break
      case 'q': // quarterly
        payEndDate = moment(payStartDate).add(3, 'months').subtract(1, 'days')._d
        break
      default:
        // monthly
        payEndDate = moment(payStartDate).add(1, 'months').subtract(1, 'days')._d
        break
    }

    return {
      startDate: new Date(payStartDate.toDateString()),
      endDate: new Date(payEndDate.toDateString()),
      paymentDate: new Date(moment(payEndDate).add(diff_paymentDate, 'days')),
      numberOfDays: numberOfDays,
      diff_paymentDate: diff_paymentDate
    }
  }

  var payrollDateModal = (options, callback) => {
    // options = {
    // 	payRuns : //
    // 	company: //
    // }
    Service.modal(
      'newPayRunModal.html',
      [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          $scope.cancel = function () {
            $uibModalInstance.dismiss()
          }

          $scope.timesheetImport = true
          $scope.timesheetsInPeriod = 0
          $scope.payPeriod = options.company.pp
          $scope.newPayrunError = ''
          $scope.newPayrunWarning = ''
          $scope.isPaymentDateEarlierThanPayrollStartDate = false
          $scope.warned = false

          var getDateObj

          function init() {
            getDateObj = getNextPayrollDate(options.payRuns, options.company, $scope.payPeriod)
            $scope.startDate = getDateObj.startDate
            $scope.endDate = getDateObj.endDate

            // to fix the format error.
            $scope.startDatePrint = $scope.startDate
            $scope.endDatePrint = $scope.endDate
            $scope.paymentDate = getDateObj.paymentDate
            countTimesheets()
          }

          function mergeDateTime(dt, timepart) {
            return moment.tz(
              dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + timepart,
              'YYYY-MM-DD HH:mm:ss',
              options.company.tz
            )
          }

          function countTimesheets() {
            const start = mergeDateTime($scope.startDatePrint, '00:00').toISOString()
            const endDisplay = mergeDateTime($scope.endDatePrint, '00:00')
            const actualEnd = endDisplay.endOf('day').toISOString()

            Http2({
              method: 'get',
              api: `/v1/timesheet/approveCount?startPeriod=${start}&endPeriod=${actualEnd}`
            }).then((res) => {
              $scope.timesheetsInPeriod = res.count
            })
          }

          init()
          // $scope.startDatePrint = payStartDate;
          // $scope.endDatePrint = $scope.endDate;
          $scope.payPeriodChanged = () => {
            // last pay run에서 가져올것
            init()
          }

          $scope.endDateOnChange = function (ev, picker) {
            // $scope.startDatePrint = moment($scope.endDate).subtract(numberOfDays, "days")._d;
            // $scope.endDatePrint = new Date($scope.endDate);
            // html 에서 date format 이 제대로 안나오는 이슈를 해결하기 위해서 endDatePrint variable 을 만들어 사용했다.
            if (new Date($scope.endDate) > new Date('2012-01-01')) {
              switch ($scope.payPeriod || $rootScope.company.pp) {
                case 'w':
                  $scope.startDatePrint = moment($scope.endDate).subtract(6, 'days')._d
                  break
                case 'f':
                  $scope.startDatePrint = moment($scope.endDate).subtract(13, 'days')._d
                  break
                case 'q':
                  if (moment($scope.endDate).endOf('month').format('D') === moment($scope.endDate).format('D')) {
                    $scope.startDatePrint = moment($scope.endDate).startOf('month').subtract(2, 'months')._d
                  } else {
                    $scope.startDatePrint = moment($scope.endDate).subtract(3, 'months').add(1, 'days')._d
                  }
                  break
                default:
                  // 만약 end date가 마지막날이면 첫날을 선택하도록 한다.
                  if (moment($scope.endDate).endOf('month').format('D') === moment($scope.endDate).format('D')) {
                    $scope.startDatePrint = moment($scope.endDate).startOf('month')._d
                  } else {
                    $scope.startDatePrint = moment($scope.endDate).subtract(1, 'months').add(1, 'days')._d
                  }
                  break
              }
              $scope.endDatePrint = new Date($scope.endDate)
              countTimesheets()
              $scope.paymentDate = new Date(moment($scope.endDate).add(getDateObj.diff_paymentDate, 'days'))
            }
          }

          $scope.paymentDateOnChange = function (ev, picker) {
            $scope.paymentDate = new Date($scope.paymentDate)
            if ($scope.paymentDate < $scope.startDatePrint) {
              $scope.isPaymentDateEarlierThanPayrollStartDate = true
              $scope.newPayrunError = 'Payment date cannot be earlier than payroll start date.'
            } else {
              $scope.isPaymentDateEarlierThanPayrollStartDate = false
              $scope.newPayrunError = ''
            }
          }

          $scope.submit_newPayRunModal = function () {
            // Reset error message
            $scope.newPayrunError = ''
            $scope.newPayrunWarning = ''

            if (new Date($scope.paymentDate) < moment($scope.startDatePrint).subtract(1, 'd')._d) {
              $scope.newPayrunError = 'Payment date cannot be earlier than payroll start date.'
              return false
            }
            const fiscalYearEnd = moment.utc(`07-01`, 'MM-DD')
            const isOutsideFiscalYear =
              moment.utc(moment($scope.endDate).format('YYYY-MM-DD'), 'YYYY_MM_DD').isBefore(fiscalYearEnd) &&
              moment.utc(moment($scope.paymentDate).format('YYYY-MM-DD'), 'YYYY_MM_DD').isSameOrAfter(fiscalYearEnd)
            if (new Date($scope.paymentDate) <= moment().add(365, 'd')._d && isOutsideFiscalYear && !$scope.warned) {
              $scope.newPayrunWarning =
                'Please note: This pay run is outside the current financial year. If this is correct, please proceed with the pay run.'
              $scope.warned = true
              return false
            }

            if (
              new Date($scope.paymentDate) > moment().add(365, 'd')._d ||
              new Date($scope.endDatePrint) > moment().add(365, 'd')._d
            ) {
              $scope.newPayrunError =
                "This pay run is more than 365 days greater than today's date. Please edit the dates so they fall into the 365-day limit."
              return false
            }

            $scope.cancel()
            $scope.warned = false
            const data = {
              payRunId: null,
              payPeriod: $scope.payPeriod || $rootScope.company.pp,
              startDate: $scope.startDatePrint,
              endDate: $scope.endDatePrint,
              paymentDate: $scope.paymentDate,
              timesheetImport: $scope.timesheetsInPeriod > 0 && $scope.timesheetImport
            }
            callback(data)
          }
        }
      ],
      { size: 'md' }
    )
  }

  const getPayslipDataEachEmployee = async (payRun, employee) => {
    return Http2({
      method: 'post',
      api: '/v1/pay-run/init-payslip',
      data: {
        payRunId: payRun._id,
        employeeId: employee._id,
        companyId: payRun.ci,
        period: payRun.pp,
        startDate: payRun.sd,
        endDate: payRun.ed,
        payDate: payRun.pd
      }
    })
  }

  const calculatePayslipValues = async (payRun, payslip, options) => {
    return Http2({
      method: 'post',
      api: '/v1/pay-run/recalculate',
      data: {
        payRun: payRun,
        payslip: payslip,
        reset: options && options.reset,
        overrideSuper: options?.overrideSuper
      },
      disableErrorMsg: false
    })
  }

  function getPreTaxDeduction(payslip, forTaxCalculation) {
    var deductionAmount = 0
    for (var index = 0; index < (payslip.ded ? payslip.ded.length : 0); index++) {
      var deduction = payslip.ded[index]
      if (!forTaxCalculation || deduction.o == 'pr') {
        deductionAmount += deduction.a
      }
    }
    return deductionAmount
  }

  function getFringeBenefit(payslip) {
    let fringeBenefit = 0
    for (let index = 0; index < (payslip.ded ? payslip.ded.length : 0); index++) {
      let deduction = payslip.ded[index]
      if (deduction.o === 'fb') {
        // fringeBenefit += deduction.a;
        fringeBenefit += deduction.a ? parseFloat(deduction.a) : 0
      }
    }
    return fringeBenefit
  }

  return {
    getNextPayrollDate: getNextPayrollDate,
    payrollDateModal: payrollDateModal,
    getPayslipDataEachEmployee,
    getPreTaxDeduction,
    getFringeBenefit,
    calculatePayslipValues
  }
})
