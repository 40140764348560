import { setModals } from '/reducers/appSlice'
import { eventNames } from '@bookipi/utils'

import { useOnboardingStore } from '@payroller/pds'

angular
  .module('starter.auth.controllers')
  .controller(
    'reactAppCtrl',
    function (
      $rootScope,
      $scope,
      $state,
      Service,
      Http,
      Analytics,
      CompanyAction,
      Http2,
      HttpPromiseAndLocalStorage,
      API_ENDPOINT,
      UserAuthFactory,
      $ngRedux,
      $window,
      LeaveAction
    ) {
      $scope.showPublishRoster = false
      $ngRedux.connect((state) => {
        const { employeeData, gbFeatures } = state?.app
        return { employeeData, gbFeatures }
      })($rootScope)

      const onAddNewEmployee = (pageInitiated, userActivity) => {
        Analytics.logEvent(eventNames.EmployeeAdditionInitiated, {
          created_by: $rootScope?.user,
          page_initiated: pageInitiated,
          user_activity: userActivity
        })
        $ngRedux.dispatch(setModals([{ kind: 'AddEmployee', options: { data: { pageInitiated, userActivity } } }]))
      }

      const successCallbacks = {
        onAddShiftCallback: () => {
          $rootScope.company = {
            ...$rootScope.company,
            TSROnboarding: {
              ...$rootScope.company.TSROnboarding,
              shiftCreated: true,
              welcomeDone: true
            }
          }

          $scope.reactState = {
            ...$scope.reactState,
            company: $rootScope.company
          }
        },
        onTimesheetCreatedCallback: () => {
          $rootScope.company = {
            ...$rootScope.company,
            TSROnboarding: {
              ...$rootScope.company.TSROnboarding,
              timesheetCreated: true
            }
          }

          $scope.reactState = {
            ...$scope.reactState,
            company: $rootScope.company
          }
        },
        onPublishScheduleCallback: (autoGeneratedTimesheet) => {
          $rootScope.company = {
            ...$rootScope.company,
            TSROnboarding: {
              ...$rootScope.company.TSROnboarding,
              publishedRoster: true,
              timesheetCreated: $rootScope.company.TSROnboarding.timesheetCreated
                ? autoGeneratedTimesheet
                : autoGeneratedTimesheet
            }
          }

          $scope.reactState = {
            ...$scope.reactState,
            company: $rootScope.company
          }
        }
      }

      const requestAIRosterOptIn = () => {
        return new Promise((resolve, reject) => {
          Http(
            {
              method: 'post',
              api: '/v2/ai-roster/opt-in',
              data: {},
              headers: {
                'x-public': 'Payroller'
              }
            },
            () => {
              $rootScope.company = {
                ...$rootScope.company,
                AIRosterEarlyAccess: {
                  optIn: true
                }
              }

              resolve()
            },
            () => reject(new Error('Failed to update company'))
          )
        })
      }

      const requestAIRosterNotInterested = () => {
        return new Promise((resolve, reject) => {
          Http(
            {
              method: 'post',
              api: '/v1/company/update',
              data: {
                company: {
                  AIRosterEarlyAccess: {
                    notInterested: true
                  }
                }
              }
            },
            () => {
              $rootScope.company = {
                ...$rootScope.company,
                AIRosterEarlyAccess: {
                  notInterested: true
                }
              }

              resolve()
            },
            () => reject(new Error('Failed to update company'))
          )
        })
      }

      const requestAIRosterMaybeLater = () => {
        const lastMaybeLater = new Date().toISOString()
        return new Promise((resolve, reject) => {
          Http(
            {
              method: 'post',
              api: '/v1/company/update',
              data: {
                company: {
                  AIRosterEarlyAccess: { lastMaybeLater }
                }
              }
            },
            () => {
              $rootScope.company = {
                ...$rootScope.company,
                AIRosterEarlyAccess: {
                  lastMaybeLater
                }
              }

              resolve()
            },
            () => reject(new Error('Failed to update company'))
          )
        })
      }

      const requestOptInOutAIRoster = async (newOptInValue) => {
        // only update the opt-in flag if they completed the AI roster onboarding
        if ($rootScope.company.AIRosterOnboardingComplete) {
          await new Promise((resolve, reject) => {
            Http(
              {
                method: 'post',
                api: '/v1/company/update',
                data: {
                  company: {
                    optInAIRoster: newOptInValue,
                    ...(newOptInValue && { optInPeopleRevamp: true })
                  }
                }
              },
              (data) => {
                $rootScope.company = {
                  ...$rootScope.company,
                  optInAIRoster: data.optInAIRoster,
                  ...(data.optInAIRoster && { optInPeopleRevamp: true })
                }

                $scope.getInitialData()

                resolve()
              },
              () => reject(new Error('Failed to update company'))
            )
          })
          $state.go('business.rosters-v2')
        } else {
          // we will update the AI roster opt-in flag after the onboarding is completed
          $state.go('ai-roster.onboarding')
        }
      }

      // We need to filter the employees that have been deleted
      $rootScope.$watch('employees', () => {
        const employees = ($rootScope.employees || []).filter((e) => e.s !== 'd' && e.s !== 'i')
        employees.sort((e1, e2) => {
          const e1Name = `${e1.f} ${e1.l}`.toLowerCase()
          const e2Name = `${e2.f} ${e2.l}`.toLowerCase()
          if (e1Name > e2Name) return 1
          if (e1Name < e2Name) return -1
          return 0
        })

        const navigate = (address) => {
          $state.go(address)
        }

        $scope.reactState = {
          count: 0,
          service: Http,
          Http2: Http2,
          employeeArr: employees,
          employeeData: $rootScope.employeeData,
          allEmployees: $rootScope.employees,
          helpers: Service,
          company: $rootScope.company || {},
          analytics: Analytics,
          CompanyAction: CompanyAction,
          go: navigate,
          navigate,
          back: () => $window.history.back(),
          isAgent: $rootScope.isAgent,
          leaves: $rootScope.leaves || [],
          user: $rootScope?.user || {},
          gbFeatures: $rootScope?.gbFeatures,
          HttpPromise: HttpPromiseAndLocalStorage,
          API_ENDPOINT: API_ENDPOINT,
          UserAuthFactory,
          onAddNewEmployee,
          getInitialData: $scope.getInitialData,
          leaveAction: LeaveAction,
          payRuns: $rootScope.payRuns || [],
          holidays: $rootScope.holidays || [],
          baseUrl: process.env.API_ENDPOINT,
          useOnboardingStoreInstance: useOnboardingStore,
          successCallbacks,
          skipOnboarding: (onSuccess, onError) => {
            Http(
              {
                method: 'post',
                api: '/v1/company/update',
                data: {
                  company: {
                    skipTSROnboarding: true
                  }
                }
              },
              () => {
                onSuccess()
              },
              () => {
                onError()
              }
            )
          },
          showPublishRoster: $rootScope.showPublishRoster,
          setShowPublishRoster: (value) => {
            $scope.reactState = {
              ...$scope.reactState,
              showPublishRoster: value
            }
          },
          // we don't need to handle the notification here
          setShowNotificationDrawer: (value) => {},
          notificationIcon: undefined,
          AIRosterEarlyAccessActions: {
            requestAIRosterOptIn,
            requestAIRosterNotInterested,
            requestAIRosterMaybeLater
          },
          updateInitialState: (newState) => {
            // we just need to fill this function since the page will automatically update the state
          },
          updateEmployeesCache: () => {
            // we just need to fill this function since the page will automatically update the state
          },
          isGrowthbookFeaturesNotEmpty: Object.keys($rootScope?.gbFeatures).length > 0,
          features: $rootScope?.gbFeatures,
          requestOptInOutAIRoster,
          hasPublishedRoster: $rootScope.hasPublishedRoster
        }
      })

      $rootScope.$watch(
        'leaves',
        function () {
          $scope.reactState = {
            ...$scope.reactState,
            leaves: $rootScope.leaves || []
          }
        },
        true
      )

      $scope.incCount = function () {
        $scope.reactState.count++
      }
    }
  )
